@import "defaults";
@use "sass:math";

/* GLOBALS */

p {
    margin: 0;
}

.container {
    max-width: 1200px;
    padding: 0 $defaultSpacing;
    width: 100%;
    margin: auto;
}

.parent-align-center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
};

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.overflow-auto {
    overflow: auto;
}

.position-absolute {
    position: absolute;
}

.flex-row {
    display: flex;
    flex-direction: row;
}


/* TEXT */

/* Weight */
.txt-weight-regular {
    font-weight: 400;
}

.txt-weight-bold {
    font-weight: bold;
}


/* Color */
.txt-col-primary {
    color: $primaryColor;
}

.txt-col-white {
    color: $textColor;
}

.txt-col-muted {
    color: $mutedTextColor;
}

.txt-col-black {
    color: $backgroundColor;
}

.txt-col-spotify {
    color: $spotifyColor;
}

/* Size */
.txt-size-regular {
    font-size: $defaultFontSize;
}

/* Alignment */
.txt-center {
    text-align: center;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}

.txt-decoration-none {
    text-decoration: none;
}

.txt-line-height-2 {
    line-height: 2;
}

/* Float */
.float-left {
    float: left;
}

.float-right {
    float: right;
}

/* MARGINS & PADDING*/
@mixin offset-type($type, $margin, $value) {
    #{$type}-#{$margin}: $value;
}

@for $i from 0 through 20 {

    @each $type in top,
    left,
    bottom,
    right {

        .margin-#{$type}-#{$i} {
            @include offset-type('margin', $type, 1rem * $i)
        }

        .padding-#{$type}-#{$i} {
            @include offset-type('padding', $type, 1rem * $i)
        }

    }
}

.margin-top-0-5 {
    margin-top: 0.5rem
}


/* DISPLAY */
.display-none {
    display: none;
}

.display-inline {
    display: inline;
}

.full-opacity {
    opacity: 1;
}

.zero-opacity {
    opacity: 0;
}

.transition {
    transition: 0.2s ease-in-out;
}

/* Buttons */

.button-primary {
    background-color: $primaryColor;
    border-radius: 5px;
    transition: $defaultTransition;

    &:hover {
        background: rgba($primaryColor, 0.8);
        cursor: pointer;
    }
}

.add-to-playlist-button {
    width: calc(100% - 21px);
    font-size: 14px;
    padding: 8px;
    line-height: 24px;
    font-family: "AllianceNo2-SemiBold",
}


.pointer {
    cursor: pointer;
}

/* Loading Container */

#loading-container {
    margin: 0.5rem 0;
}


/* Main */

#landing-section-top {
    min-height: 82vh;
    padding: $defaultSpacing;
}

#landing-header {
    margin: 0;
    margin-bottom: 10px;
}

#landing-headline {
    margin: 0;
    font-size: 17px;
}

/* Multi Box */

#multi-box {
    background-color: $textColor;
    border-radius: 9px;
    width: 95%;
    max-width: 560px;
    margin: auto;
    margin-top: $defaultSpacing*1.5;
    margin-bottom: $defaultSpacing*1.5;
    padding: $defaultSpacing / 2;
}

.multi-box-max-width {
    max-width: 560px;
    margin: auto;
}

/* Steps */

#results-list-container {
    padding: $defaultSpacing ;
}

#setlist-view-container {
    padding: $defaultSpacing ;
    overflow: auto;
}

.results-list-artists {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $defaultSpacing / 1.5;

    @media (max-width: $mobileBreakpoint) {
        grid-template-columns: 1fr;
        grid-gap: $defaultSpacing / 1.5;
    }
}

.results-list-artists.four-cols {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: $defaultSpacing * 1;

    @media (max-width: $mobileBreakpoint) {
        grid-template-columns: 1fr 1fr;
        grid-gap: $defaultSpacing / 1.5;
    }

    @media (max-width: 400px) {
        grid-template-columns: 1fr;
        grid-gap: $defaultSpacing / 1.5;
    }
}

#results-list-header {
    width: 100%;
    color: $backgroundColor;
    padding-bottom: $defaultSpacing;
}

#results-heading {
    font-size: 1.25rem;
}

.artist-cell {
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    width: 100%;
    min-height: 125px;

    &:before {
        border-radius: 9px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba($mutedTextColor, 0.5);
    }

    @media (min-width: 401px) {

        &:nth-child(4n + 1),
        &:nth-child(4n) {
            &:before {
                background-color: rgba($primaryColor, 0.5);
            }
        }
    }

    @media (max-width: $mobileBreakpoint) {
        &:nth-child(2n) {
            &:before {
                background-color: rgba($primaryColor, 0.5);
            }
        }
    }
}

.artist-cell-no-fill {
    position: relative;
    width: 100%;
    color: $backgroundColor;
}

.artist-cell-header {
    position: absolute;
    color: $textColor;
    bottom: $defaultSpacing / 1.5;
    right: $defaultSpacing / 1.5;
    font-weight: 500;
}


.play-icon {
    width: 24px;
    font-size: 12px;
}

/* Featured Section */

#featured-section {
    background-color: $textColor;
    color: $backgroundColor;
    min-height: 15vh;
    border-radius: 20px 20px 0px 0px;
    padding: $defaultSpacing*2 $defaultSpacing;
    position: relative;
    box-shadow: 0px 103px 109px 100px #61AAFF;
}

/* Footer */
footer {
    text-align: center;
    padding-bottom: $defaultSpacing
}

/* BG */
.noise-bg {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1000;
    background-color: $backgroundColor;
    overflow: hidden;
}

.noise-bg,
.noise-bg:before {
    position: fixed;
}

.noise-bg:before {
    content: "";
    top: -10rem;
    left: -10rem;
    width: calc(100% + 20rem);
    height: calc(100% + 20rem);
    z-index: -9999;
    background-image: url('https://comino-digital.s3.ap-southeast-2.amazonaws.com/setlistPredictor/background-noise.png');
    opacity: .85;

    -webkit-animation: noise 1s steps(2) infinite;
    animation: noise 1s steps(2) infinite;
}


@-webkit-keyframes noise {
    0% {
        transform: translate3d(0, 9rem, 0)
    }

    10% {
        transform: translate3d(-1rem, -4rem, 0)
    }

    20% {
        transform: translate3d(-8rem, 2rem, 0)
    }

    30% {
        transform: translate3d(9rem, -9rem, 0)
    }

    40% {
        transform: translate3d(-2rem, 7rem, 0)
    }

    50% {
        transform: translate3d(-9rem, -4rem, 0)
    }

    60% {
        transform: translate3d(2rem, 6rem, 0)
    }

    70% {
        transform: translate3d(7rem, -8rem, 0)
    }

    80% {
        transform: translate3d(-9rem, 1rem, 0)
    }

    90% {
        transform: translate3d(6rem, -5rem, 0)
    }

    to {
        transform: translate3d(-7rem, 0, 0)
    }
}

@keyframes noise {
    0% {
        transform: translate3d(0, 9rem, 0)
    }

    10% {
        transform: translate3d(-1rem, -4rem, 0)
    }

    20% {
        transform: translate3d(-8rem, 2rem, 0)
    }

    30% {
        transform: translate3d(9rem, -9rem, 0)
    }

    40% {
        transform: translate3d(-2rem, 7rem, 0)
    }

    50% {
        transform: translate3d(-9rem, -4rem, 0)
    }

    60% {
        transform: translate3d(2rem, 6rem, 0)
    }

    70% {
        transform: translate3d(7rem, -8rem, 0)
    }

    80% {
        transform: translate3d(-9rem, 1rem, 0)
    }

    90% {
        transform: translate3d(6rem, -5rem, 0)
    }

    to {
        transform: translate3d(-7rem, 0, 0)
    }
}

/* Table */
#events-table {
    width: 100%;
    border: 1px solid $mutedTextColor;
    border-radius: 10px;
    border-spacing: 0;

    td {
        padding: $defaultSpacing * 1.5 $defaultSpacing * 2 ;

        @media screen and (max-width: $mobileBreakpoint) {
            padding: $defaultSpacing * 0.8;
        }
    }

    tr:not(:last-of-type) {
        td {
            border-bottom: 1px solid $mutedTextColor;
        }
    }

   
}


/* Panel */
.panel-primary {
    min-height: 110px;
    width: 100%;
    background: linear-gradient(90deg, rgba(24, 130, 255, 0.8) 0%, rgba(97, 170, 255, 0.8) 100%);
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "AllianceNo2-SemiBold", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 1.25rem;
    transition: $defaultTransition;

    &:hover {
        opacity: 0.9;
    }
}

