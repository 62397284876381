/* Default Theme Options*/
$primaryColor: #61AAFF;
$textColor: #ffffff;
$mutedTextColor: #8B949E;
$backgroundColor: #141414;
$mobileBreakpoint: 767px;
$defaultSpacing: 1rem;
$defaultFontSize: 1rem;
$defaultTransition: 0.2s;

$spotifyColor: #1DB954;
/* Fonts */

@font-face {
    font-family: "AllianceNo2";
    src: url("../fonts/AllianceNo2-Regular.woff") format("woff"),
        url("../fonts/AllianceNo2-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "AllianceNo2-Bold";
    src: url("../fonts/AllianceNo2-Bold.woff") format("woff"),
        url("../fonts/AllianceNo2-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "AllianceNo2-SemiBold";
    src: url("../fonts/AllianceNo2-SemiBold.woff") format("woff"),
        url("../fonts/AllianceNo2-SemiBold.woff2") format("woff2");
}

@font-face {
    font-family: "AllianceNo2-SemiBoldItalic";
    src: url("../fonts/AllianceNo2-SemiBoldItalic.woff") format("woff"),
        url("../fonts/AllianceNo2-SemiBoldItalic.woff2") format("woff2");
}

html {
    font-family: "AllianceNo2", -apple-system, BlinkMacSystemFont, sans-serif;
    color: $textColor;
    font-size: $defaultFontSize;
    height: 100%;    
}

input, button {
    font-family: "AllianceNo2", -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
    margin: 0;
    overflow-x: hidden;
}

h1 {
    font-family: "AllianceNo2-SemiBold", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: $defaultFontSize * 2.75;
    margin-bottom: $defaultSpacing;

    i {
        font-family: "AllianceNo2-SemiBoldItalic", -apple-system, BlinkMacSystemFont, sans-serif;
    }
}

section {
    padding: $defaultSpacing;
}

#music-slider {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
}

#music-slider:focus {
    outline: none;
}

#music-slider::-webkit-slider-runnable-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.1rem;
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 2px;
}

#music-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -5px;
    background-color: $primaryColor;
    border-radius: 10px;
    height: 0.75rem;
    width: 0.75rem;
}

#music-slider:focus::-webkit-slider-thumb, #music-slider:focus::-moz-range-thumb {
    border: 1px solid none;
    outline: 3px solid none;
    outline-offset: 0.125rem;
}

#music-slider::-moz-range-track {
    background-color: $primaryColor;
    border-radius: 10px;
    height: 0.75rem;
    width: 0.75rem;
}

#music-slider::-moz-range-thumb {
    border: none;
    border-radius: 0;
    background-color: $primaryColor;
    border-radius: 10px;
    height: 0.75rem;
    width: 0.75rem;
}
