.music-player-container {
    position: fixed;
    bottom: 0;
    right: 0.75rem;
    
    @media screen and (max-width: 767px) {
        width: 100%;
        left: 0;
        right: inherit;
    }
}


.music-player {
    height: 100%;
    max-width: 400px;
    width: 100%;
    background: white;
    float: right;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 2px solid #61AAFF;
    font-weight: bold;
    border-radius: 8px;
    color: black;

    @media screen and (max-width: 767px) {
        height: 110px;
        max-width: calc(100% - 1.5rem - 4px);
        width: 100%;
        margin: 0;
        border-radius: 8px 8px 0px 0px;
    }
}

.audio-controller {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;
    height: 100%;
    width: 100%;

    .song-container {
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            margin-bottom: 5px;
        }
    }
    
    .column {
        flex: 1 1 auto;
        align-self: center;
        text-align: left;
        height: 100%;

        img {
            width: auto;
            max-height: 130px;

            @media screen and (max-width: 767px) {
                max-height: 110px;
            }
        }
    }

    .right-content {
        display: flex;
        flex-direction: column;
        min-height: 130px;
        min-width: 250px;

        @media screen and (max-width: 767px) {
            min-height: 110px; 
        }

        .song-name {
            max-width: 100%;
            overflow-wrap: break-word;
            font-size: 16px;

            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
        
        .song-album {
            max-width: 100%;
            overflow-wrap: break-word;
            font-weight: normal;
            font-size: 14px;

            @media screen and (max-width: 767px) {
                font-size: 11px;
            }
        }

        .spotify-link {
            width: calc(100% - 6px);
            padding: 3px;
            text-align: center;

            .spotify-link-text {
                display: inline;
                line-height: 30px;
                font-size: 13px;
            }

            img {
                display: inline;
                width: 22px;
                padding-right: 8px;
                vertical-align: text-top;
                line-height: 30px;
            }

            @media screen and (max-width: 767px) {
                padding: 0px;

                .spotify-link-text {
                    line-height: 25px;
                    font-size: 10px;
                }

                img {
                    width: 18px;
                    vertical-align: sub;
                    padding-right: 8px;
                    padding-top: 4px;
                }
            }
        }

        .preview-text {
            color: #61AAFF;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 100;
        }

        .timer {
            color: #61AAFF;
            font-size: 13px;
            text-transform: uppercase;
        }

        a {
            text-decoration: none;
        }
    }
}

.opp-ends {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: stretch;
}