@import "../../../styles/defaults.scss";
@use "sass:math";

$imageHeight: 35px;
$headerHeight: 80px;

.menu-container {
  font-family: "AllianceNo2-SemiBold";
  font-size: 20px;
  line-height: $imageHeight;
  display: flex;
  align-items: center;
  padding: $defaultSpacing * 1.5;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
}

.inner-header-container {
  display: flex;
  margin: auto;
}

.menu-logo {
  line-height: 0;
  margin-right: $defaultSpacing;
}

.menu-logo img {
  max-height: $imageHeight;
  max-width: 100px;
  flex-shrink: 0;
}

.menu-container input {
  display: none;
}

