@import "./defaults.scss";

/* Search Input */
#search-input-form {
    position: relative;
}

#search-input {
    height: 55px;
    background-color: transparent;
    border: none;
    font-size: $defaultFontSize;
    background: url(../img/magnifying-glass-solid.svg) no-repeat scroll 10px;
    background-size: 25px;
    padding: 0px 0px 0px 55px;
    width: calc(100% - 50px - 110px);
    text-align: left;
    color: $backgroundColor;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $backgroundColor;
        opacity: 1;
    }
}


#search-input-submit {
    position: absolute;
    right: 0px;
    width: 20%;
    min-width: 60px;
    max-width: 100px;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 1;
}

#search-input-submit-inner {
    cursor: pointer;
    background-color: rgba($primaryColor, 1);
    border-radius: 5px;
    color: $textColor;
    outline: none;
    padding: $defaultSpacing 0px;

    &:hover {
        background-color: rgba($primaryColor, 0.9);
    }
}


/* waveform-loaders */

.hide-spinner {
    opacity: 0;
}

.show-spinner {
    opacity: 1;
    transition: 0.2s ease-in;
}

#waveform-spinner {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

#waveform-spinner span {
    display: block;
    width: 4px;
    height: 100%;
    background: $primaryColor;
    animation: waveform-loader 0.75s linear infinite;
    margin: 0 1.5px;
    border-radius: 1rem;
}

#waveform-spinner span:nth-child(2) {
    animation-delay: 0.25s;
}

#waveform-spinner span:nth-child(3) {
    animation-delay: 0.5s;
}

#waveform-spinner span:nth-child(4) {
    animation-delay: 0.25s;
}


@keyframes waveform-loader {
    50% {
        height: 20%;
    }
    100% {
        height: 100%;
    }
}